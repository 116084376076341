import { CustomError, getErrMsgFromRes } from "./util";
/**
 * 提交转码请求
 */
function queryTransProgress(task, opt, retryRemain, callbacks) {
    if (task.aborted) {
        return;
    }
    const { config } = task;
    const xhr = new XMLHttpRequest();
    const url = config.transServiceUrl + opt.url;
    xhr.onreadystatechange = function () {
        if (task.aborted) {
            return;
        }
        if (xhr.readyState !== 4) {
            return;
        }
        let result;
        try {
            result = JSON.parse(xhr.responseText);
        }
        catch (e) {
            result = {
                errMsg: '返回json无法解析',
                errCode: 500
            };
        }
        if (xhr.status === 200) {
            if (result.code !== 200) {
                task.abort();
                config.onError(new CustomError(getErrMsgFromRes(result, opt.param, url)));
            }
            else if (!result[opt.retBody]) {
                task.abort();
                config.onError(new CustomError(getErrMsgFromRes(result, opt.param, url)));
            }
            else if (result[opt.retBody].status === 40) {
                callbacks.onComplete(result);
            }
            else if (result[opt.retBody].status === 30) {
                if (typeof callbacks.onProgress === 'function') {
                    callbacks.onProgress(result);
                }
                setTimeout(() => {
                    queryTransProgress(task, opt, retryRemain, callbacks);
                }, config.checkTransProgressInterval);
            }
            else {
                task.abort();
                const requestId = (result === null || result === void 0 ? void 0 : result.requestId) || 'not found';
                let param = '{}';
                try {
                    param = JSON.stringify(opt.param, null, 2);
                }
                catch (err) { }
                config.onError(new CustomError(`request fail ${JSON.stringify({
                    url,
                    requestId,
                    statusText: xhr.statusText,
                    param: param
                }, null, 2)}`));
            }
        }
        else if (xhr.status.toString().match(/^5/)) {
            setTimeout(() => {
                queryTransProgress(task, opt, retryRemain - 1, callbacks);
            }, config.checkTransProgressInterval);
        }
        else if (xhr.status.toString() === "429") {
            //当前向服务器请求query的用户太多，服务器频率限制
            setTimeout(() => {
                queryTransProgress(task, opt, retryRemain - 1, callbacks);
            }, config.checkTransProgressInterval + Math.random() * 3000);
        }
        else {
            if (retryRemain > 0) {
                /**
                 * 如果还有重试次数，则隔5s后重试。这时可能网络环境已经发生了变化
                 */
                console.error(`queryTransProgress(${url}) error. retry after 3 seconds. ${new Date().toTimeString()}`);
                setTimeout(() => {
                    queryTransProgress(task, opt, retryRemain - 1, callbacks);
                }, 3500);
            }
            else {
                task.abort();
                if (xhr.status !== 0) {
                    config.onError(new CustomError(`queryTransProgress(${url}) error: ${xhr.status} ${xhr.statusText}`));
                }
                else {
                    config.onError(new CustomError(`queryTransProgress(${url}) error. please check your network`));
                }
            }
        }
    };
    xhr.open('post', url);
    xhr.setRequestHeader('content-type', 'application/json');
    xhr.setRequestHeader('appkey', task.authInfo.appkey);
    xhr.setRequestHeader('curtime', task.authInfo.curtime.toString());
    xhr.setRequestHeader('nonce', task.authInfo.nonce);
    xhr.setRequestHeader('checksum', task.authInfo.checksum);
    xhr.timeout = 10 * 1000;
    xhr.send(JSON.stringify(opt.param));
}
export default queryTransProgress;
