/**
 * 将转码结果解析为白板可以直接使用的格式
 * @param value
 * @returns
 */
export function parseDynamicTransResult(result) {
    let { resultUrl, pageCount, width, height, backupResultUrls } = result.data;
    let pageIndexUrl = resultUrl.endsWith('/') ? resultUrl + 'index.json' : resultUrl + '/index.json';
    if (pageIndexUrl.indexOf('http:') === 0) {
        pageIndexUrl = 'https:' + pageIndexUrl.slice(5);
    }
    backupResultUrls = Array.isArray(backupResultUrls) ? backupResultUrls.map(url => {
        return url.endsWith('/') ? url + 'index.json' : url + '/index.json';
    }) : [];
    width || (width = 1080);
    height || (height = 1080);
    return {
        width: width,
        height: height,
        url: pageIndexUrl,
        pageCount: pageCount,
        backupResultUrls,
        trans: 'h5'
    };
}
export function parseStaticTransResult(result, mode) {
    let { pageCount, format, resultUrl, width, height, backupResultUrls } = result.data;
    width || (width = 1080);
    height || (height = 1080);
    if (mode === 'array') {
        /**
         * 3.6.1之前的静态文档转码，返回结果格式为：
         * [
         *    {width: xxx, height: xxx, url: '??1??.jpg'},
         *    {width: xxx, height: xxx, url: '??2??.jpg'}
         * ]
         */
        let params = [];
        for (let i = 0; i < pageCount; i++) {
            let url = `${resultUrl}${i + 1}.${format}`;
            if (url.indexOf('http:') === 0) {
                url = 'https:' + url.slice(5);
            }
            params.push({
                url,
                width: width,
                height: height
            });
        }
        return params;
    }
    else {
        /**
         * 互动白板3.6.1及以后的静态文档转码。offset表示图片的url从1开始
         */
        let template = resultUrl.endsWith('/') ? `${resultUrl}{index}.${format}` : `${resultUrl}/{index}.${format}`;
        if (template.indexOf('http:') === 0) {
            template = 'https:' + template.slice(5);
        }
        backupResultUrls = Array.isArray(backupResultUrls) ? backupResultUrls.map(url => {
            let template = url.endsWith('/') ? `${url}{index}.${format}` : `${url}/{index}.${format}`;
            if (template.indexOf('http:') === 0) {
                template = 'https:' + template.slice(5);
            }
            return template;
        }) : [];
        return {
            template,
            width,
            height,
            pageCount,
            offset: 1,
            backupResultUrls,
            trans: 'pic'
        };
    }
}
export function parseMediaTrans(res, trans) {
    if (!res || !res.ret) {
        return null;
    }
    const isVideo = res.ret.width !== '0' && res.ret.width !== 0;
    let url, origin;
    if (trans && res.ret.sdMp4Url && res.ret.duration > 0) {
        url = res.ret.sdMp4Url;
        origin = res.ret.origUrl;
    }
    else if (!trans && res.ret.origUrl && res.ret.duration > 0) {
        url = res.ret.origUrl;
        origin = res.ret.origUrl;
    }
    if (url && origin) {
        if (url.indexOf('http:') === 0) {
            url = 'https:' + url.slice(5);
        }
        if (origin.indexOf('http:') === 0) {
            origin = 'https:' + origin.slice(5);
        }
        return {
            url: url,
            origin: origin,
            video: isVideo,
            duration: res.ret.duration,
            trans: trans
        };
    }
    else {
        return null;
    }
}
