import NosUploader from "@yxfe/nos-uploader";
import { parseDynamicTransResult, parseStaticTransResult } from "./parser";
import queryTransProgress from "./queryTmpl";
import sendRequest from "./requestTmpl";
import { CustomError, getFileSuffix } from "./util";
class Task {
    constructor(fileOrDocInfo, authInfo, config) {
        this.abort = () => {
            if (this.uploadTask) {
                this.uploadTask.abort();
            }
            clearTimeout(this.transProgressTimer);
            this.aborted = true;
        };
        this.startUploadPhase = (config) => {
            sendRequest(this, {
                param: {
                    name: this.file.name,
                    docFormat: getFileSuffix(this.file.name)
                },
                url: '/upload/init'
            }, 3, (res) => {
                if (this.config.onBeginUpload) {
                    this.config.onBeginUpload({
                        docId: res.data.docId
                    });
                }
                this.uploadTask = NosUploader.createTask(this.file, {
                    bucketName: res.data.bucket,
                    objectName: res.data.objectKey,
                    token: res.data.token,
                    ctx: '',
                    payload: {
                        docId: res.data.docId
                    }
                }, config);
            });
        };
        this.startTransUrlPhase = () => {
            sendRequest(this, {
                param: {
                    docUrl: this.docInfo.url,
                    docFormat: this.docInfo.format,
                    transcodeFormat: this.config.transcodeFormat,
                    dpi: 300
                },
                url: '/transcode/commitUrl'
            }, 3, (res) => {
                if (this.config.onUploadComplete) {
                    this.config.onUploadComplete({
                        docUrl: res.data.docUrl
                    });
                }
                this.startTransAt = Date.now();
                this.sendProgressFeedback();
                this.config.onTransBegin({
                    taskId: res.data.taskId
                });
                queryTransProgress(this, {
                    retBody: 'data',
                    url: '/transcode/query',
                    param: {
                        taskId: res.data.taskId
                    }
                }, 5, {
                    onComplete: (res) => {
                        clearTimeout(this.transProgressTimer);
                        this.config.onTransProgress(1);
                        if (this.config.transcodeFormat === 'h5') {
                            this.config.onTransComplete(parseDynamicTransResult(res));
                        }
                        else {
                            this.config.onTransComplete(parseStaticTransResult(res, this.config.staticDocType));
                        }
                    }
                });
            });
        };
        this.startTransPhase = (res) => {
            if (!res.payload.docId) {
                return this.config.onError(new CustomError(`找不到docId`));
            }
            sendRequest(this, {
                url: '/transcode/commit',
                param: {
                    docId: res.payload.docId,
                    transcodeFormat: this.config.transcodeFormat,
                    dpi: 300
                }
            }, 3, (res) => {
                this.startTransAt = Date.now();
                this.sendProgressFeedback();
                this.config.onTransBegin({
                    taskId: res.data.taskId
                });
                queryTransProgress(this, {
                    retBody: 'data',
                    url: '/transcode/query',
                    param: {
                        taskId: res.data.taskId
                    }
                }, 5, {
                    onComplete: (res) => {
                        clearTimeout(this.transProgressTimer);
                        this.config.onTransProgress(1);
                        if (this.config.transcodeFormat === 'h5') {
                            this.config.onTransComplete(parseDynamicTransResult(res));
                        }
                        else {
                            this.config.onTransComplete(parseStaticTransResult(res, this.config.staticDocType));
                        }
                    }
                });
            });
        };
        /**
         * 模拟传输转码进度
         */
        this.sendProgressFeedback = () => {
            clearTimeout(this.transProgressTimer);
            this.transProgressTimer = setTimeout(() => {
                if (!this.startTransAt) {
                    this.startTransAt = Date.now();
                }
                const elapsed = Math.floor((Date.now() - this.startTransAt) / 1000);
                let progress = (1 - Math.pow(0.97716, elapsed));
                progress = progress > 0.99 ? 0.9999 : progress;
                this.config.onTransProgress(progress);
                this.sendProgressFeedback();
            }, 1000);
        };
        this.config = config;
        this.authInfo = authInfo;
        if (fileOrDocInfo instanceof File) {
            this.file = fileOrDocInfo;
            /**
             * 先上传，再转码
             */
            const uploadconfig = {
                onProgress: config.onUploadProgress,
                onError: config.onError
            };
            if (config.directUploadAddr)
                uploadconfig.directUploadAddr = config.directUploadAddr;
            uploadconfig.onComplete = (res) => {
                if (config.onUploadComplete) {
                    sendRequest(this, {
                        param: { docId: res.payload.docId },
                        url: '/doc/get'
                    }, 3, (newRes) => {
                        if (newRes.data && newRes.data.docUrl) {
                            config.onUploadComplete({
                                docUrl: newRes.data.docUrl
                            });
                        }
                        else {
                            config.onUploadComplete({
                                docUrl: null
                            });
                        }
                        this.startTransPhase(res);
                    });
                }
                else {
                    this.startTransPhase(res);
                }
            };
            const uploadConfig = NosUploader.createConfig(uploadconfig);
            this.startUploadPhase(uploadConfig);
        }
        else {
            this.docInfo = fileOrDocInfo;
            this.startTransUrlPhase();
        }
    }
}
export default Task;
