import { CustomError, getErrMsgFromRes } from "./util";
/**
 * 提交转码请求
 */
function sendRequest(task, opt, retryRemain, callback) {
    if (task.aborted) {
        return;
    }
    const { config } = task;
    const xhr = new XMLHttpRequest();
    const url = config.transServiceUrl + opt.url;
    xhr.onreadystatechange = function () {
        if (task.aborted) {
            return;
        }
        if (xhr.readyState !== 4) {
            return;
        }
        let result;
        try {
            result = JSON.parse(xhr.responseText);
        }
        catch (e) {
            result = {
                errMsg: '返回json无法解析',
                errCode: 500
            };
        }
        if (xhr.status === 200) {
            if (result.code !== 200) {
                config.onError(new CustomError(getErrMsgFromRes(result, opt.param, url)));
            }
            else {
                callback(result);
            }
        }
        else if (xhr.status.toString().match(/^5/)) {
            sendRequest(task, opt, retryRemain - 1, callback);
        }
        else {
            if (retryRemain > 0) {
                /**
                 * 如果还有重试次数，则隔5s后重试。这时可能网络环境已经发生了变化
                 */
                console.error(`request(${url}) error. retry after 3 seconds ${new Date().toTimeString()}`);
                setTimeout(() => {
                    sendRequest(task, opt, retryRemain - 1, callback);
                }, 3500);
            }
            else {
                if (xhr.status !== 0) {
                    config.onError(new CustomError(`request(${url}) error: ${xhr.status} ${xhr.statusText}`));
                }
                else {
                    config.onError(new CustomError(`request(${url}) error. please check your network`));
                }
            }
        }
    };
    xhr.open('post', url);
    xhr.setRequestHeader('content-type', 'application/json');
    xhr.setRequestHeader('appkey', task.authInfo.appkey);
    xhr.setRequestHeader('curtime', task.authInfo.curtime.toString());
    xhr.setRequestHeader('nonce', task.authInfo.nonce);
    xhr.setRequestHeader('checksum', task.authInfo.checksum);
    xhr.timeout = 10 * 1000;
    xhr.send(JSON.stringify(opt.param));
}
export default sendRequest;
