import NosUploader from "@yxfe/nos-uploader";
import { parseMediaTrans } from "./parser";
import queryTransProgress from "./queryTmpl";
import sendRequest from "./requestTmpl";
import { CustomError } from "./util";
class MediaTask {
    constructor(file, authInfo, config) {
        this.abort = () => {
            if (this.uploadTask) {
                this.uploadTask.abort();
            }
            clearTimeout(this.transProgressTimer);
            this.aborted = true;
        };
        this.startUploadPhase = (config) => {
            sendRequest(this, {
                param: {
                    originFileName: this.file.name
                },
                url: '/upload/init'
            }, 3, (res) => {
                if (this.config.onBeginUpload) {
                    this.config.onBeginUpload();
                }
                this.uploadTask = NosUploader.createTask(this.file, {
                    bucketName: res.ret.bucket,
                    objectName: res.ret.object,
                    token: res.ret.xNosToken,
                    ctx: ''
                }, config);
            });
        };
        /**
         * 开启转码及查询之旅
         * @param vid
         */
        this.startTransPhase = (vid) => {
            if (this.config.trans) {
                if (!this.config.presetId) {
                    return this.config.onError(new CustomError('未提供转码模板'));
                }
                /**
                 * 需要转码
                 */
                sendRequest(this, {
                    param: {
                        vids: [vid],
                        presetId: this.config.presetId
                    },
                    url: '/transcode/resetmulti'
                }, 3, (res) => {
                    if (res.ret && res.ret.successCount === 1) {
                        this.startTransAt = Date.now();
                        this.sendProgressFeedback();
                        this.config.onTransBegin({
                            vid: vid
                        });
                        queryTransProgress(this, {
                            url: '/video/get',
                            retBody: 'ret',
                            param: {
                                vid: vid
                            }
                        }, 5, {
                            onComplete: (res) => {
                                clearTimeout(this.transProgressTimer);
                                this.config.onTransProgress(1);
                                const parsedRes = parseMediaTrans(res, this.config.trans);
                                if (parsedRes) {
                                    this.config.onTransComplete(parsedRes);
                                }
                                else {
                                    this.config.onError(new CustomError(`未能够获得转码视频，或者媒体文件长度为0. vid: ${vid}`));
                                }
                            }
                        });
                    }
                    else {
                        this.config.onError(new CustomError('/transcode/resetmulti failed.' + `vid: ${vid}` + res.toString()));
                    }
                });
            }
            else {
                /**
                 * 无需转码
                 */
                this.sendProgressFeedback();
                this.config.onTransBegin({
                    vid: vid
                });
                queryTransProgress(this, {
                    url: '/video/get',
                    retBody: 'ret',
                    param: {
                        vid: vid
                    }
                }, 5, {
                    onComplete: (res) => {
                        clearTimeout(this.transProgressTimer);
                        this.config.onTransProgress(1);
                        const parsedRes = parseMediaTrans(res, this.config.trans);
                        if (parsedRes) {
                            this.config.onTransComplete(parsedRes);
                        }
                        else {
                            this.config.onError(new CustomError(`未能够获得转码视频，或者媒体文件长度为0. vid: ${vid}`));
                        }
                    }
                });
            }
        };
        /**
         * 模拟传输转码进度
         */
        this.sendProgressFeedback = () => {
            clearTimeout(this.transProgressTimer);
            this.transProgressTimer = setTimeout(() => {
                if (!this.startTransAt) {
                    this.startTransAt = Date.now();
                }
                const elapsed = Math.floor((Date.now() - this.startTransAt) / 1000);
                let progress = (1 - Math.pow(0.97716, elapsed));
                progress = progress > 0.99 ? 0.9999 : progress;
                this.config.onTransProgress(progress);
                this.sendProgressFeedback();
            }, 1000);
        };
        this.config = config;
        this.authInfo = authInfo;
        this.file = file;
        if (this.config.trans && !this.config.presetId) {
            return this.config.onError(new CustomError('未提供转码模板'));
        }
        /**
         * 先上传，再转码
         */
        const uploadconfig = {
            onProgress: config.onUploadProgress,
            onError: config.onError
        };
        if (config.directUploadAddr)
            uploadconfig.directUploadAddr = config.directUploadAddr;
        uploadconfig.onComplete = (res) => {
            // bucketName: "jdvodlw5zhmwt"
            // ctx: "69eea5ac-9a34-f12b-36ef-4167da24da4d"
            // objectName: "9d7c0b4d120d4ea18fb32d6aaf7d6592.aac"
            // token: "UPLOAD ab1856bb39044591939d7b94e1b8e5ee:DukR/EGoyC1bdRhFjJmLmxXO4b6oJcyqM+ipaIEdus0=:eyJDYWxsYmFja1VybCI6Imh0dHA6Ly81OS4xMTEuMjguMzEveGhyL3ZvZC91cGxvYWQvY2FsbGJhY2siLCJDYWxsYmFja0JvZHkiOiJ7XCJidWNrZXRcIjpcIiQoQnVja2V0KVwiLFwib2JqZWN0U2l6ZVwiOlwiJChPYmplY3RTaXplKVwiLFwic3RvcmFnZVR5cGVcIjpcIm5vc1wiLFwib2JqZWN0XCI6XCIkKE9iamVjdClcIn0iLCJCdWNrZXQiOiJqZHZvZGx3NXpobXd0IiwiUmVnaW9uIjoiSkQiLCJPYmplY3QiOiI5ZDdjMGI0ZDEyMGQ0ZWExOGZiMzJkNmFhZjdkNjU5Mi5hYWMiLCJFeHBpcmVzIjoxNjkzNDY0ODg0fQ=="
            config.onUploadComplete({
                bucket: res.bucketName,
                object: res.objectName
            });
            sendRequest(this, {
                param: {
                    objectNames: [res.objectName]
                },
                url: '/video/query'
            }, 3, (queryRes) => {
                if (queryRes.ret && queryRes.ret.count === 1) {
                    /**
                     * 无需转码，直接返回上传结束的url
                     */
                    this.startTransPhase(queryRes.ret.list[0].vid);
                }
                else if (config.onError) {
                    config.onError(new CustomError(`上传后无法获取vid. objectName为: ${res.objectName}`));
                }
            });
        };
        const uploadConfig = NosUploader.createConfig(uploadconfig);
        this.startUploadPhase(uploadConfig);
    }
}
export default MediaTask;
