export class CustomError extends Error {
    constructor(message, code) {
        super('DocConverterError:' + message);
        this.errCode = code;
        this.errMsg = message;
    }
}
export const getFileSuffix = (fileName) => {
    return fileName.slice(fileName.lastIndexOf('.') + 1);
};
export const getErrMsgFromRes = (result, param, url) => {
    let errMsg = '';
    try {
        errMsg = JSON.stringify({
            result,
            param,
            url
        }, null, 2);
    }
    finally {
        if (!errMsg) {
            errMsg = `request url: ${url} return code: ${result.code}`;
        }
    }
    return errMsg;
};
