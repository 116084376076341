import { CustomError } from "./util";
class Config {
    constructor(opts) {
        this.transServiceUrl = 'https://vcloud.163.com/app/wboard';
        /**
         * array: 静态转码返回每一页的width，height，url的数组
         * Array<{
         *    width: number
         *    height: number
         *    url: string
         * }>
         *
         * template: 静态转码返回整体的width，height，url以及count的对象
         * {
         *    width: number,
         *    height: number,
         *    template: 'https://www.example.com/{index}.jpg'
         *    pageCount: number,
         *    offset: number
         * }
         */
        this.staticDocType = 'template';
        this.onBeginUpload = () => { };
        this.onUploadProgress = () => { };
        this.onUploadComplete = () => { };
        this.onTransBegin = () => { };
        this.onTransProgress = () => { };
        this.onTransComplete = () => { };
        this.onError = () => { };
        this.checkTransProgressInterval = 4000;
        this.transcodeFormat = 'jpg';
        if (opts.directUploadAddr)
            this.directUploadAddr = opts.directUploadAddr;
        if (opts.transcodeFormat)
            this.transcodeFormat = opts.transcodeFormat;
        if (opts.onError)
            this.onError = opts.onError;
        if (opts.onBeginUpload)
            this.onBeginUpload = opts.onBeginUpload;
        if (opts.onUploadProgress)
            this.onUploadProgress = opts.onUploadProgress;
        if (opts.onUploadComplete)
            this.onUploadComplete = opts.onUploadComplete;
        if (opts.onTransBegin)
            this.onTransBegin = opts.onTransBegin;
        if (opts.onTransProgress)
            this.onTransProgress = opts.onTransProgress;
        if (opts.onTransComplete)
            this.onTransComplete = opts.onTransComplete;
        if (opts.checkTransProgressInterval)
            this.checkTransProgressInterval = opts.checkTransProgressInterval;
        if (opts.staticDocType)
            this.staticDocType = opts.staticDocType;
        if (opts.transServiceUrl)
            this.transServiceUrl = opts.transServiceUrl;
        if (this.transServiceUrl.endsWith('/')) {
            this.transServiceUrl = this.transServiceUrl.slice(0, this.transServiceUrl.length - 1);
        }
        if (!['jpg', 'png', 'h5'].includes(this.transcodeFormat)) {
            throw new CustomError(`transcodeFormat只能是h5, jpg或者png`);
        }
    }
}
export default Config;
