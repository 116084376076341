import { parseDynamicTransResult, parseStaticTransResult } from "./parser";
import queryTransProgress from "./queryTmpl";
class QueryTask {
    constructor(opt, authInfo, config) {
        this.complete = false;
        this.abort = () => {
            clearTimeout(this.transProgressTimer);
            this.aborted = true;
        };
        /**
         * 模拟传输转码进度
         */
        this.sendProgressFeedback = () => {
            clearTimeout(this.transProgressTimer);
            if (this.complete) {
                return;
            }
            this.transProgressTimer = setTimeout(() => {
                if (!this.startTransAt) {
                    this.startTransAt = Date.now();
                }
                const elapsed = Math.floor((Date.now() - this.startTransAt) / 1000);
                let progress = (1 - Math.pow(0.97716, elapsed));
                progress = progress > 0.99 ? 0.9999 : progress;
                this.config.onTransProgress(progress);
                this.sendProgressFeedback();
            }, 1000);
        };
        const { taskId } = opt;
        this.config = config;
        this.authInfo = authInfo;
        this.taskId = taskId.toString();
        this.config.onTransProgress(0);
        queryTransProgress(this, {
            retBody: 'data',
            url: '/transcode/query',
            param: {
                taskId: this.taskId
            }
        }, 5, {
            onComplete: (res) => {
                clearTimeout(this.transProgressTimer);
                this.complete = true;
                this.config.onTransProgress(1);
                if (this.config.transcodeFormat === 'h5') {
                    this.config.onTransComplete(parseDynamicTransResult(res));
                }
                else {
                    this.config.onTransComplete(parseStaticTransResult(res, this.config.staticDocType));
                }
            },
            onProgress: (res) => {
                if (!this.startTransAt) {
                    this.startTransAt = Number(res.data.createTime);
                    this.sendProgressFeedback();
                }
            }
        });
    }
}
export default QueryTask;
