class Config {
    constructor(opts) {
        this.directUploadAddr = 'https://wanproxy-web.127.net';
        this.retryCount = 4;
        this.trunkSize = 4 * 1024 * 1024;
        this.trunkUploadTimeout = 50000;
        this.getOffsetTimeout = 10000;
        this.version = '1.0';
        this.logger = console;
        this.onError = function (err) {
        };
        this.onProgress = function (progress) {
        };
        this.onComplete = function (uploadInfo) {
        };
        for (let key in opts) {
            this[key] = opts[key];
        }
    }
}
export default Config;
