import Config from "./Config";
import fileState from "./fileState";
import Task from "./Task";
const NosUploader = {
    /**
     * 每次创建新的文件上传任务时，会检查是否cache过多，或者上传过期，然后删除不再符合要求的文件信息
     */
    /**
     * 最多存储默认6个文件上传信息
     */
    maxFileCache: 6,
    /**
     * 默认1天后过期
     */
    expireTime: 1000 * 60 * 60 * 24,
    getFileUploadInformation: (file) => {
        const fileKey = fileState.getFileKey(file);
        const fileInfo = fileState.getFileInfo(fileKey);
        if (fileInfo === null) {
            return null;
        }
        else if (Date.now() - fileInfo.modifyAt > NosUploader.expireTime) {
            fileState.removeFileInfo(fileKey);
            return null;
        }
        else {
            return {
                uploadInfo: Object.assign({ bucketName: fileInfo.bucket, objectName: fileInfo.obj, token: fileInfo.token, ctx: fileInfo.ctx }, (fileInfo.payload ? {
                    payload: fileInfo.payload
                } : {})),
                complete: fileInfo.end
            };
        }
    },
    setMaxFileCache: (val) => {
        NosUploader.maxFileCache = val;
    },
    setExpireTime: (ms) => {
        NosUploader.expireTime = ms;
    },
    printCaches: () => {
        const keys = Object.keys(localStorage);
        for (let key of keys) {
            const fileInfo = fileState.getFileInfo(key);
            if (fileInfo) {
                console.log(fileInfo, 'modifiedAt', new Date(fileInfo.modifyAt).toTimeString());
            }
        }
    },
    createConfig: (params) => {
        return new Config(params);
    },
    createTask: (file, param, config) => {
        return new Task(file, param, config);
    }
};
export default NosUploader;
