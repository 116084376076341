class MediaConfig {
    constructor(opts) {
        this.trans = false;
        this.transServiceUrl = 'https://vcloud.163.com/app/vod/';
        this.onBeginUpload = () => { };
        this.onError = (err) => {
        };
        this.onUploadProgress = (progress) => {
        };
        this.onUploadComplete = () => {
        };
        this.onTransProgress = (progress) => {
        };
        this.onTransComplete = () => {
        };
        this.onTransBegin = (res) => {
        };
        this.checkTransProgressInterval = 4000;
        this.presetId = opts.presetId;
        if (opts.directUploadAddr)
            this.directUploadAddr = opts.directUploadAddr;
        if (opts.onError)
            this.onError = opts.onError;
        if (opts.onBeginUpload)
            this.onBeginUpload = opts.onBeginUpload;
        if (opts.onUploadProgress)
            this.onUploadProgress = opts.onUploadProgress;
        if (opts.onUploadComplete)
            this.onUploadComplete = opts.onUploadComplete;
        if (opts.onTransBegin)
            this.onTransBegin = opts.onTransBegin;
        if (opts.onTransProgress)
            this.onTransProgress = opts.onTransProgress;
        if (opts.onTransComplete)
            this.onTransComplete = opts.onTransComplete;
        if (opts.checkTransProgressInterval)
            this.checkTransProgressInterval = opts.checkTransProgressInterval;
        if (opts.transServiceUrl)
            this.transServiceUrl = opts.transServiceUrl;
        if (opts.trans)
            this.trans = opts.trans;
        if (this.transServiceUrl.endsWith('/')) {
            this.transServiceUrl = this.transServiceUrl.slice(0, this.transServiceUrl.length - 1);
        }
    }
}
export default MediaConfig;
