import fileState from "./fileState";
import { CustomError } from "./util";
function getOffset(task, retryRemain, callback) {
    if (task.uploadState !== 'uploading') {
        return;
    }
    const { config, param } = task;
    const context = fileState.getUploadContext(task.fileKey);
    if (!context) {
        return callback(0);
    }
    const xhr = new XMLHttpRequest();
    const url = config.directUploadAddr
        + `/${param.bucketName}`
        + `/${encodeURIComponent(param.objectName)}`
        + '?uploadContext'
        + `&context=${context}`
        + `&version=${config.version}`;
    xhr.onreadystatechange = function () {
        var _a;
        if (task.uploadState === 'abort') {
            return;
        }
        if (xhr.readyState !== 4) {
            return;
        }
        let result;
        try {
            result = JSON.parse(xhr.responseText);
        }
        catch (e) {
            result = {
                errMsg: 'JsonParseError in getOffset',
                errCode: 500
            };
        }
        if (xhr.status === 200) {
            if (result.errCode) {
                task.config.onError(new CustomError(result.errMsg, result.errCode));
            }
            else {
                callback(result.offset);
            }
        }
        else if (xhr.status.toString().match(/^5/)) {
            getOffset(task, retryRemain - 1, callback);
        }
        else {
            if (retryRemain > 0) {
                /**
                 * 如果还有重试次数，则隔5s后重试。这时可能网络环境已经发生了变化
                 */
                if (typeof ((_a = config.logger) === null || _a === void 0 ? void 0 : _a.error) === 'function') {
                    config.logger.error(`getOffset(${url}) error. retry after 3 seconds. ${new Date().toTimeString()}`);
                }
                setTimeout(() => {
                    getOffset(task, retryRemain - 1, callback);
                }, 3500);
            }
            else {
                fileState.removeFileInfo(task.fileKey);
                if (xhr.status) {
                    config.onError(new CustomError(`getOffset(${url}) error: ${xhr.status} ${xhr.statusText}`));
                }
                else {
                    config.onError(new CustomError(`getOffset(${url}) error. no Error Code`));
                }
            }
        }
    };
    xhr.open('get', url);
    xhr.setRequestHeader('x-nos-token', param.token);
    xhr.timeout = config.getOffsetTimeout;
    xhr.send();
}
export default getOffset;
