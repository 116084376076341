import Config from "./Config";
import MediaConfig from "./MediaConfig";
import MediaQueryTask from "./MediaQueryTask";
import MediaTask from "./MediaTask";
import QueryTask from "./QueryTask";
import Task from "./Task";
const DocConverter = {
    createConfig: (opts) => {
        return new Config(opts);
    },
    createMediaConfig: (opts) => {
        return new MediaConfig(opts);
    },
    createTask: (fileOrDocInfo, authInfo, config) => {
        return new Task(fileOrDocInfo, authInfo, config);
    },
    createMediaTask: (file, authInfo, config) => {
        return new MediaTask(file, authInfo, config);
    },
    createQueryTask: (opt, authInfo, config) => {
        return new QueryTask(opt, authInfo, config);
    },
    createMediaQueryTask: (opt, authInfo, config) => {
        return new MediaQueryTask(opt, authInfo, config);
    }
};
export default DocConverter;
