import fileState from './fileState';
import { CustomError } from './util';
/**
 * 上传分片
 * @method uploadTrunk
 * @param  {Object}   param     AJAX参数
 *  	param中的属性有：
 * 		serveIp {String} IP地址
 * 		bucketName {String} 桶名
 * 		objectName {String} 对象名
 * 		token {String} 上传凭证
 * @param  {Object}   trunkData 分片数据
 * 		trunkData属性有：
 * 		file {File} File对象
 *      fileKey {String} 文件名和文件大小的组合值
 *      offset {long} 当前分片在整个对象中的起始偏移量
 *      trunkSize {long} 分片大小
 *      trunkEnd {long} 分片结束位置
 *      context: {String} 上传上下文
 * @param  {Function} callback  文件（非分片）上传成功回调函数
 * 		回调函数参数：
 * 		trunkData {Object} 分片数据
 * @return {void}
 */
function uploadTrunk(task, offset, retryRemain, callback) {
    if (task.uploadState !== 'uploading') {
        return;
    }
    const { param, config } = task;
    const blobSlice = File.prototype.slice;
    const context = param.ctx !== undefined ? param.ctx : '';
    const isComplete = (offset + config.trunkSize) > task.file.size;
    const trunkEnd = isComplete ? task.file.size : (offset + config.trunkSize);
    const xhr = new XMLHttpRequest();
    const url = config.directUploadAddr
        + `/${param.bucketName}`
        + `/${encodeURIComponent(param.objectName)}`;
    xhr.upload.onprogress = function (e) {
        var progress = 0;
        if (e.lengthComputable) {
            progress = (offset + e.loaded) / task.file.size;
            config.onProgress(progress);
        }
        else {
            config.onError(new CustomError('browser does not support query upload progress'));
        }
    };
    xhr.onreadystatechange = function () {
        var _a, _b;
        if (task.uploadState === 'abort') {
            return;
        }
        if (xhr.readyState !== 4) {
            return;
        }
        let result;
        try {
            result = JSON.parse(xhr.responseText);
        }
        catch (e) {
            if (typeof ((_a = config.logger) === null || _a === void 0 ? void 0 : _a.error) === 'function') {
                config.logger.error('JsonParseError in uploadTrunk', e);
            }
            result = {
                errMsg: 'JsonParseError in uploadTrunk'
            };
        }
        if (xhr.status === 200) {
            task.setContext(result.context);
            if (isComplete) {
                callback();
                task.setComplete();
            }
            else {
                uploadTrunk(task, result.offset, config.retryCount, callback);
            }
        }
        else if (xhr.status.toString().match(/^5/)) {
            //服务器出错重试
            if (retryRemain > 0) { //同一个边缘节点重试两次
                uploadTrunk(task, offset, retryRemain - 1, callback);
            }
            else { //重试完输出错误信息
                fileState.removeFileInfo(task.fileKey);
                config.onError(new CustomError(result.errMsg, result.errCode));
            }
        }
        else {
            if (retryRemain > 0) {
                /**
                 * 如果还有重试次数，则隔5s后重试。这时可能网络环境已经发生了变化
                 */
                if (typeof ((_b = config.logger) === null || _b === void 0 ? void 0 : _b.error) === 'function') {
                    config.logger.error(`uploadTrunk(${url}) error. retry after 3 seconds. ${new Date().toTimeString()}`);
                }
                setTimeout(() => {
                    uploadTrunk(task, offset, retryRemain - 1, callback);
                }, 3500);
            }
            else {
                fileState.removeFileInfo(task.fileKey);
                if (xhr.status) {
                    config.onError(new CustomError(`uploadTrunk(${url}) error: ${xhr.status} ${xhr.statusText}`));
                }
                else {
                    config.onError(new CustomError(`uploadTrunk(${url}) error. no Error Code. Please check your network`));
                }
            }
        }
    };
    xhr.open('post', url
        + `?offset=${offset}`
        + `&complete=${isComplete}`
        + `&context=${context}`
        + `&version=${config.version}`);
    xhr.setRequestHeader('x-nos-token', param.token);
    if (task.file.type) {
        xhr.setRequestHeader('content-type', task.file.type);
    }
    xhr.timeout = config.trunkUploadTimeout;
    // 修复支付宝环境 webview 中上传不成功问题
    // 本质上是支付宝 webview 的 bug，猜测是从本地读文件失败导致 post 请求发不上去
    // SDK 做了一些绕过的策略，先把本地文件通过 FileReader 读到内存，再从内存中的数据 转 blob 提交给表单。
    // 需要小心 FileReader 的兼容性，IE9 它毕竟是不支持的
    // 详见 jira：http://jira.netease.com/browse/IM-2500
    if (typeof FileReader !== "undefined") {
        var reader = new FileReader();
        reader.addEventListener('load', function (event) {
            var _a;
            if ((_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.result) {
                // xhr.send(new Blob([event.target.result]));
                /**
                 * 修复在粤政易得webview中上传时，发送数据content-length为0的问题
                 * 问题原因是粤政易的webview上传blob时，发送数据会为空
                 * 若改为发送File, 发送的数据会回退为multipart/form-data, 且没有正确的设置content-type，这导致nos无法正确解析上传的数据
                 *
                 * 改为send ArrayBuffer之后，现在能够正确上传
                 */
                xhr.send(event.target.result);
                return;
            }
            config.onError(new CustomError('Read ArrayBuffer failed'));
        });
        // 修复 IM-4094，磁盘删除文件后 fileReader 读取失败没有反馈。
        reader.addEventListener('error', function (event) {
            // uploadOptions.onerror(event.target.error)
            const error = event.target.error;
            config.onError(new CustomError(`Read ArrayBuffer error. ${error.toString()}`, error.code));
        });
        reader.readAsArrayBuffer(blobSlice.call(task.file, offset, trunkEnd));
    }
    else {
        xhr.send(blobSlice.call(task.file, offset, trunkEnd));
    }
}
export default uploadTrunk;
