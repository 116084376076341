import { parseMediaTrans } from "./parser";
import queryTransProgress from "./queryTmpl";
import { CustomError } from "./util";
class MediaQueryTask {
    constructor(opt, authInfo, config) {
        this.complete = false;
        this.abort = () => {
            clearTimeout(this.transProgressTimer);
            this.aborted = true;
        };
        /**
         * 模拟传输转码进度
         */
        this.sendProgressFeedback = () => {
            clearTimeout(this.transProgressTimer);
            if (this.complete) {
                return;
            }
            this.transProgressTimer = setTimeout(() => {
                if (!this.startTransAt) {
                    this.startTransAt = Date.now();
                }
                const elapsed = Math.floor((Date.now() - this.startTransAt) / 1000);
                let progress = (1 - Math.pow(0.97716, elapsed));
                progress = progress > 0.99 ? 0.9999 : progress;
                this.config.onTransProgress(progress);
                this.sendProgressFeedback();
            }, 1000);
        };
        const { vid } = opt;
        this.config = config;
        this.authInfo = authInfo;
        this.vid = vid;
        this.config.onTransProgress(0);
        queryTransProgress(this, {
            url: '/video/get',
            retBody: 'ret',
            param: {
                vid: vid
            }
        }, 5, {
            onProgress: (res) => {
                var _a;
                if (!this.startTransAt && ((_a = res === null || res === void 0 ? void 0 : res.ret) === null || _a === void 0 ? void 0 : _a.createTime)) {
                    this.startTransAt = Number(res.ret.createTime);
                }
                this.sendProgressFeedback();
            },
            onComplete: (res) => {
                this.complete = true;
                clearTimeout(this.transProgressTimer);
                this.config.onTransProgress(1);
                const parsedRes = parseMediaTrans(res, this.config.trans);
                if (parsedRes) {
                    this.config.onTransComplete(parsedRes);
                }
                else {
                    this.config.onError(new CustomError(`未能够获得转码视频，或者媒体文件长度为0. vid: ${vid}`));
                }
            }
        });
    }
}
export default MediaQueryTask;
