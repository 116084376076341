import fileState from "./fileState";
import getOffset from "./getOffset";
import uploadTrunk from "./uploadTrunk";
class Task {
    constructor(file, param, config) {
        this.uploadState = 'paused';
        this.config = config;
        this.file = file;
        this.param = param;
        this.fileKey = fileState.initFile(param, file);
        this.resume();
    }
    resume() {
        if (this.uploadState === 'uploading') {
            return;
        }
        this.setUploadState('uploading');
        const config = this.config;
        getOffset(this, config.retryCount, (offset) => {
            uploadTrunk(this, offset, config.retryCount, () => {
                this.setUploadState('ended');
                if (typeof config.onComplete === 'function') {
                    config.onComplete(this.param);
                }
            });
        });
    }
    pause() {
        this.setUploadState('paused');
    }
    abort() {
        this.setUploadState('abort');
        fileState.removeFileInfo(this.fileKey);
    }
    setUploadState(state) {
        if (state !== this.uploadState) {
            this.uploadState = state;
        }
    }
    setContext(context) {
        fileState.setUploadContext(this.fileKey, context);
        this.param.ctx = context;
    }
    setComplete() {
        fileState.setComplete(this.fileKey);
        this.setUploadState('ended');
    }
}
export default Task;
