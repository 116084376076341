import NosUploader from ".";
let storage = window.localStorage;
if (!window.localStorage ||
    typeof window.localStorage.getItem !== 'function' ||
    typeof window.localStorage.setItem !== 'function' ||
    typeof window.localStorage.removeItem !== 'function') {
    storage = {
        privateObj: {},
        setItem: function (key, value) {
            storage.privateObj[key] = value;
        },
        getItem: function (key) {
            return storage.privateObj[key];
        },
        removeItem: function (key) {
            delete storage.privateObj[key];
        },
        getKeys: function () {
            return Object.keys(storage.privateObj);
        }
    };
}
const fileState = {
    getFileKey(file) {
        const fileSize = file.size.toString();
        const fileLastModifiedAt = file.lastModified.toString();
        return file.name + fileSize.slice(fileSize.length - 5) + fileLastModifiedAt.slice(fileLastModifiedAt.length - 5);
    },
    getFileInfo(key) {
        const infoString = storage.getItem(key);
        if (!infoString) {
            return null;
        }
        else {
            try {
                return JSON.parse(infoString);
            }
            catch (err) {
                /**
                 * 可能clearCache使用了非fileKey来获取fileInfo
                 */
                return null;
            }
        }
    },
    initFile(param, file) {
        fileState.clearExpiredInfo();
        const fileKey = this.getFileKey(file);
        const fileInfo = {
            ctx: (param.ctx !== undefined) ? param.ctx : '',
            bucket: param.bucketName,
            obj: param.objectName,
            token: param.token,
            modifyAt: Date.now(),
            end: false
        };
        if (param.payload) {
            fileInfo.payload = param.payload;
        }
        /**
         * 初始化
         */
        storage.setItem(fileKey, JSON.stringify(fileInfo));
        return fileKey;
    },
    setUploadContext(fileKey, context) {
        const fileInfo = this.getFileInfo(fileKey);
        if (fileInfo) {
            fileInfo.ctx = context;
            storage.setItem(fileKey, JSON.stringify(fileInfo));
        }
    },
    setComplete(fileKey) {
        const fileInfo = this.getFileInfo(fileKey);
        if (fileInfo) {
            fileInfo.modifyAt = Date.now();
            fileInfo.end = true;
            storage.setItem(fileKey, JSON.stringify(fileInfo));
        }
    },
    getUploadContext(fileKey) {
        const fileInfo = this.getFileInfo(fileKey);
        return fileInfo ? fileInfo.ctx : '';
    },
    removeFileInfo(fileKey) {
        storage.removeItem(fileKey);
    },
    /**
     * 清理超出容量限制的存储，或者超出时间限制的存储
     */
    clearExpiredInfo() {
        const keys = typeof storage.getKeys === 'function' ? storage.getKeys() : Object.keys(storage);
        const now = Date.now();
        let tempArray = [];
        /**
         * 删除超时的文件记录
         */
        for (let key of keys) {
            const info = fileState.getFileInfo(key);
            if (info === null || (now - info.modifyAt > NosUploader.expireTime)) {
                storage.removeItem(key);
            }
            else {
                tempArray.push({
                    fileInfo: info,
                    key
                });
            }
        }
        /**
         * 超出了最大存储设置，删除最老上传的文件记录
         */
        if (tempArray.length > NosUploader.maxFileCache) {
            const sortedArray = tempArray.sort((a, b) => {
                return b.fileInfo.modifyAt - a.fileInfo.modifyAt;
            });
            const obsoleteArray = sortedArray.slice(NosUploader.maxFileCache);
            for (let entry of obsoleteArray) {
                storage.removeItem(entry.key);
            }
        }
    }
};
export default fileState;
